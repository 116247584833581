import axios from 'axios';
import { baseUrlPlatform } from '../resources/constants';
import { WatchedType } from '../models/user';

export const getPopularBackdrops = (): Promise<string[]> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}mixed-contents/popular-backdrops`)
            .then((response: any) => resolve(response.data.results ?? []))
            .catch((error) => reject(error));
    });
};

export const getPopularPosters = (): Promise<string[]> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}mixed-contents/popular-posters`)
            .then((response: any) => resolve(response.data.results ?? []))
            .catch((error) => reject(error));
    });
};

export const getPopularSuggestions = (): Promise<WatchedType[]> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}mixed-contents/popular-suggestions`)
            .then((response: any) => resolve(response.data.results ?? []))
            .catch((error) => reject(error));
    });
};
